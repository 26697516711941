<template>
  <div>
    <b-card no-body>
      <b-row>
        <b-col
          md="4"
        >
          <v-autocomplete
            v-model="selectedSystem"
            :items="systemsOptions"
            :label="$t('AppCustomer.EditTabRelated.system')"
            item-text="texto"
            item-value="id"
            clearable
          />
        </b-col>
        <b-col
          md="4"
        >
          <b-form-group
            v-if="selectedSystem !== 1"
            :label="$t('AppCustomer.EditTabRelated.keyClient')"
            label-for="alias"
          >
            <b-form-input
              v-model="customerKey"
            />
          </b-form-group>
          <v-autocomplete
            v-if="selectedSystem === 1"
            v-model="customerKey"
            :items="customersSysExpertOptions"
            :label="$t('AppCustomer.EditTabRelated.keySysExpert')"
            item-text="texto"
            item-value="id"
            clearable
          />
        </b-col>
        <b-col
          md="4"
        >
          <b-button
            class="mt-2"
            variant="primary"
            :disabled="selectedSystem === null || customerKey === '' || customerKey === null"
            @click="onAddRelatedCustomer"
          >
            {{ $t('Lists.Add') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-table
        :fields="fields"
        :items="relatedCustomers"
        responsive
      >
        <template #cell(actions)="data">
          <b-button
            variant="outline-danger"
            @click="onDeleteRelatedCustomer(data.item.clienteRelacionadoId)"
          >
            <feather-icon
              icon="Trash2Icon"
            />
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard, BFormInput, BFormGroup, BRow, BCol, BButton,
} from 'bootstrap-vue'
import optionService from '@/services/option.service'
import i18n from '@/libs/i18n'
import customerService from '@/services/customer.service'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BTable,
    BCard,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    customerId: {
      required: true,
    },
  },
  setup(props) {
    const { fetchSystems, fetchCustomersSysExpertOptions } = optionService()
    const { fetchRelatedCustomers, createRelatedCustomer, deleteRelatedCustomer } = customerService()

    const systemsOptions = ref([])
    const customersSysExpertOptions = ref([])
    const relatedCustomers = ref([])
    const fields = [
      { key: 'clienteId', label: i18n.t('AppCustomer.EditTabRelated.customerID') },
      { key: 'sistemaNombre', label: i18n.t('AppCustomer.EditTabRelated.system') },
      { key: 'claveNumerica', label: i18n.t('dashboards.auditoriaMolex.rectifications.documentKey') },
      { key: 'clienteNombre', label: i18n.t('AppCustomer.columns.name') },
      { key: 'actions', label: i18n.t('Lists.Actions') },
    ]
    fetchSystems(data => {
      systemsOptions.value = data
    })
    const refetchData = () => {
      fetchRelatedCustomers(props.customerId, data => {
        relatedCustomers.value = data.clientesRelacionados

        fetchCustomersSysExpertOptions(dataCustomers => {
          customersSysExpertOptions.value = dataCustomers
        })
      })
    }

    refetchData()

    const selectedSystem = ref(null)
    const customerKey = ref('')

    const onAddRelatedCustomer = () => {
      createRelatedCustomer({
        clienteId: props.customerId,
        sistemaId: selectedSystem.value,
        claveNumerica: customerKey.value,
      }, () => {
        refetchData()
      })
    }

    const onDeleteRelatedCustomer = clienteRelacionadoId => {
      deleteRelatedCustomer({ idCustomer: props.customerId, idRelatedCustomer: clienteRelacionadoId }, () => {
        refetchData()
      })
    }

    return {
      systemsOptions,
      customersSysExpertOptions,
      fields,
      relatedCustomers,
      selectedSystem,
      customerKey,

      onAddRelatedCustomer,
      onDeleteRelatedCustomer,
    }
  },
}
</script>

<style lang="scss">
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
