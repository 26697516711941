<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Partes-->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="t('AppCustomer.EditTabPart.partField')"
            label-for="alias"
          >
            <b-form-input
              id="alias"
              v-model="clienteData.cfgLytPartesColumnaParte"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Descripcion -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="t('AppCustomer.EditTabPart.descriptionField')"
            label-for="nombre"
          >
            <b-form-input
              id="nombre"
              v-model="clienteData.cfgLytPartesColumnaDescripcion"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Fraccion -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="t('AppCustomer.EditTabPart.fractionField')"
            label-for="rfc"
          >
            <b-form-input
              id="rfc"
              v-model="clienteData.cfgLytPartesColumnaFraccion"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Nico -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="t('AppCustomer.EditTabPart.fractionField')"
            label-for="domicilio"
          >
            <b-form-input
              id="domicilio"
              v-model="clienteData.cfgLytPartesColumnaNico"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Nico -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="t('AppCustomer.EditTabPart.fractionField')"
            label-for="domicilio"
          >
            <b-form-input
              id="domicilio"
              v-model="clienteData.cfgLytPartesColumnaFraccionNico"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        type="submit"
      >
        {{ $t('Lists.saveChanges') }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const clienteData = ref(JSON.parse(JSON.stringify(props.customerData)))

    return {
      clienteData,
    }
  },
}
</script>

<style>

</style>
