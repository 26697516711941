<template>
  <div>
    <list
      ref="refProviderContacList"
      sort-by="proveedorTiContactoId"
      refetch-records-name="destinatarioClientes"
      key-field="proveedorTiContactoId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fecthRecord"
      :delete-method="deleteCustomerRecipients"
      :sort-dir-desc="false"
    />
  </div>
</template>

<script>
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import customerService from '@/services/customer.service'
import router from '@/router'
import List from '@/components/List.vue'

export default {
  components: {
    List,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    customerId: {
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup() {
    const refCustomerRecipientsList = ref(null)
    const filters = ref([])
    // Services
    const {
      fetchCustomerRecipients,
      deleteCustomerRecipients,
    } = customerService()

    const fecthRecord = (data, callback) => {
      const DataWId = { ...data, clienteId: router.currentRoute.params.clienteId }
      fetchCustomerRecipients(DataWId, records => {
        callback(records)
      })
    }
    // Table Handlers
    const tableColumns = [
      {
        key: 'clienteDestinatarioExpedienteDigitalId',
        label: i18n.t('AppCustomer.CustomerRecipients.columns.customerId'),
        sortable: true,
        sortKey: 'ClienteId',
      },
      {
        key: 'nombreCompleto',
        label: i18n.t('AppCustomer.CustomerRecipients.columns.fullName'),
        sortable: true,
        sortKey: 'Alias',
      },
      {
        key: 'alias',
        label: i18n.t('AppCustomer.CustomerRecipients.columns.alias'),
        sortable: true,
        sortKey: 'Nombre',
      },
      {
        key: 'correo',
        label: i18n.t('AppCustomer.CustomerRecipients.columns.email'),
        sortable: true,
        sortKey: 'Correo',
      },
      {
        key: 'localizacionIdioma.descripcion',
        label: i18n.t('AppCustomer.CustomerRecipients.columns.language'),
        sortable: true,
        sortkey: 'LocalizacionIdiomaId',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: true,
        sortKey: 'Accion',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'), // idioma
        aclAction: 'read',
        aclResource: 'Clientes',
        routeName: 'apps-recipients-edit',
        params: [router.currentRoute.params.clienteId, 'clienteDestinatarioExpedienteDigitalId'],
        icon: 'Edit2Icon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Clientes',
        params: ['clienteDestinatarioExpedienteDigitalId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('AppCustomer.CustomerRecipients.newRecipients'), // idioma
        aclAction: 'create',
        aclResource: 'Clientes',
        routeName: 'apps-recipients-new',
        params: [router.currentRoute.params.clienteId],
        icon: 'PlusCircleIcon',
      },
    ])
    return {
      // Table Data
      fecthRecord,
      tableColumns,
      tableActions,
      actions,
      deleteCustomerRecipients,
      refCustomerRecipientsList,
      filters,
    }
  },
}
</script>

<style lang="scss">
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
