<template>
  <component :is="customerData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="customerData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('AppCustomer.errorFetching') }}
      </h4>
      <div class="alert-body">
        {{ $t('AppCustomer.noUserFound') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-customers-list'}"
        >
          {{ $t('AppCustomer.userList') }}
        </b-link>
        {{ $t('AppCustomer.forOthersUsers') }}
      </div>
    </b-alert>

    <b-tabs
      v-if="customerData"
      pills
    >

      <!-- Tab: Customer -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppCustomer.Customer') }}</span>
        </template>
        <customer-edit-tab-Customer
          :customer-data="customerData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Related -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="GitBranchIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppCustomer.customersRelated') }}</span>
        </template>
        <customer-edit-tab-related
          class="mt-2 pt-75"
          :customer-id="customerData.clienteId"
        />
      </b-tab>
      <!-- Tab: Part template -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="DatabaseIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppCustomer.templateParts') }}</span>
        </template>
        <customer-edit-tab-part-template
          class="mt-2 pt-75"
          :customer-data="customerData"
        />
      </b-tab>
      <!-- Tab: Receivers -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="MailIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppCustomer.receiver') }}</span>
        </template>
        <customer-edit-tab-receiver
          class="mt-2 pt-75"
          :customer-id="customerData.clienteId"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import customerStoreModule from '../customerStoreModule'
import CustomerEditTabCustomer from './CustomerEditTabCustomer.vue'
import CustomerEditTabRelated from './CustomerEditTabRelated.vue'
import CustomerEditTabPartTemplate from './CustomertEditTabPartTemplate.vue'
import CustomerEditTabReceiver from './CustomerEditReceiver.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    CustomerEditTabCustomer,
    CustomerEditTabRelated,
    CustomerEditTabPartTemplate,
    CustomerEditTabReceiver,
  },
  setup() {
    const customerData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-customer/fetchCustomer', { clienteId: router.currentRoute.params.clienteId })
      .then(response => { customerData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          customerData.value = undefined
        }
      })

    return {
      customerData,
    }
  },
}
</script>

<style>

</style>
